import * as api from '../request';
import { API_URL } from '../../../app-config/constants';
const RESOURCE = `${API_URL}/objective-categories`;
const withId = categories => ({ id: 'objectiveCategories', categories });
get.operation = 'READ';
export function get() {
    return api.get(RESOURCE).then(withId);
}
put.operation = 'COMMAND';
export function put(objectiveCategories = []) {
    return api.put(RESOURCE, { postData: objectiveCategories }).then(withId);
}
