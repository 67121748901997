import { build } from 'ladda-cache';
import { logger } from 'ladda-logger';
import { observable } from 'ladda-observable';
import { fromPairs, map, mapValues, partial, startsWith, toPairs } from 'lodash';
import * as actionItem from './custom/ActionItem';
import * as activityStream from './custom/ActivityStream';
import * as admin from './custom/Admin';
import * as aiSummaryAssistant from './custom/AiSummaryAssistant';
import * as anytimeFeedbackDefault from './custom/AnytimeFeedbackDefault';
import * as anytimeFeedbackExport from './custom/AnytimeFeedbackExport';
import * as anytimeFeedbackRequest from './custom/AnytimeFeedbackRequest';
import * as anytimeFeedbackResponse from './custom/AnytimeFeedbackResponse';
import * as anytimeFeedbackStats from './custom/AnytimeFeedbackStats';
import * as auditLog from './custom/AuditLog';
import * as auditLogCategories from './custom/AuditLogCategories';
import * as auditLogMedium from './custom/AuditLogMedium';
import * as auditLogTypes from './custom/AuditLogTypes';
import * as autocompletion from './custom/Autocompletion';
import * as automations from './custom/Automations';
import * as automationsOptions from './custom/AutomationsOptions';
import * as automationsSettings from './custom/AutomationsSettings';
import * as backups from './custom/Backups';
import * as badges from './custom/Badges';
import * as bouncedEmail from './custom/BouncedEmail';
import * as bouncedEmailAggregate from './custom/BouncedEmailAggregate';
import * as calendar from './custom/Calendar';
import * as calendarIntegration from './custom/CalendarIntegration';
import * as company from './custom/Company';
import * as companyAnalytics from './custom/CompanyAnalytics';
import * as companyAnalyticsCycleData from './custom/CompanyAnalyticsCycleData';
import * as companyAnalyticsLeaveRisk from './custom/CompanyAnalyticsLeaveRisk';
import * as companyDateTimeSettings from './custom/CompanyDateTimeSettings';
import * as companyExperimentsMini from './custom/CompanyExperimentsMini';
import * as companyList from './custom/CompanyList';
import * as contentItemPreview from './custom/ContentItemPreview';
import * as currentUser from './custom/CurrentUser';
import * as customer from './custom/Customer';
import * as customerCO from './custom/CustomerCO';
import * as customerHealth from './custom/CustomerHealth';
import * as customerPaymentDoc from './custom/CustomerPaymentDoc';
import * as cycleAdmins from './custom/CycleAdmins';
import * as cycleFeedbackResponse from './custom/CycleFeedbackResponse';
import * as deletedUser from './custom/DeletedUser';
import * as downloads from './custom/Downloads';
import * as dummyApi from './custom/Dummy';
import * as emailConfiguration from './custom/EmailConfiguration';
import * as emailReportSubscriptions from './custom/EmailReportSubscriptions';
import * as emailTemplate from './custom/EmailTemplate';
import * as emailTemplateDtoPreview from './custom/EmailTemplateDtoPreview';
import * as emailTemplateMini from './custom/EmailTemplateMini';
import * as emailTemplateModificationInfo from './custom/EmailTemplateModificationInfo';
import * as emailTemplatePreview from './custom/EmailTemplatePreview';
import * as emailVerification from './custom/EmailVerification';
import * as experiments from './custom/Experiments';
import * as experimentsMini from './custom/ExperimentsMini';
import * as exportJob from './custom/ExportJob';
import * as featureFlag from './custom/FeatureFlag';
import * as featureSelectionSettings from './custom/FeatureSelectionSettings';
import * as featureSetting from './custom/FeatureSetting';
import * as feedbackCycles from './custom/FeedbackCycles';
import * as feedbackParticipantMini from './custom/FeedbackParticipantMini';
import * as feedbackParticipants from './custom/FeedbackParticipants';
import * as feedbackRequestManagement from './custom/FeedbackRequestManagement';
import { feedbackTemplateAnytime, feedbackTemplateCycle } from './custom/FeedbackTemplate';
import * as feedbackTemplateOrder from './custom/FeedbackTemplateOrder';
import * as googleAppsLoginConfig from './custom/GoogleAppsLoginConfig';
import * as helpfulResources from './custom/HelpfulResources';
import * as importReviews from './custom/ImportReviews';
import * as inAppFeedback from './custom/InAppFeedback';
import * as mailEvents from './custom/MailEvents';
import * as meetingActions from './custom/MeetingActions';
import * as meetingCalendarIntegration from './custom/MeetingCalendarIntegration';
import * as meetingCalendarSettings from './custom/MeetingCalendarSettings';
import * as meetingDashboard from './custom/MeetingDashboard';
import * as meetingInsights from './custom/MeetingInsights';
import * as meetingMetadata from './custom/MeetingMetadata';
import * as meetings from './custom/Meetings';
import * as meetingSpaces from './custom/MeetingSpaces';
import * as meetingSpacesCount from './custom/MeetingSpacesCount';
import * as meetingSpacesMini from './custom/MeetingSpacesMini';
import * as meetingsRelevant from './custom/MeetingsRelevant';
import * as meetingTemplates from './custom/MeetingTemplates';
import * as messagePeerFeedbackExport from './custom/MessagePeerFeedbackExport';
import * as messageSettings from './custom/MessageSettings';
import * as messagesRelevant from './custom/MessagesRelevant';
import * as messageStatistics from './custom/MessageStatistics';
import * as notifications from './custom/Notifications';
import * as notificationsCount from './custom/NotificationsCount';
import * as objectiveCategories from './custom/ObjectiveCategories';
import * as objectiveClippy from './custom/ObjectiveClippy';
import * as objectiveCommentCount from './custom/ObjectiveCommentCount';
import * as objectiveComments from './custom/ObjectiveComments';
import * as objectiveCycle from './custom/ObjectiveCycles';
import * as objectivePreview from './custom/ObjectivePreview';
import * as objectiveRatings from './custom/ObjectiveRatings';
import * as objectives from './custom/Objectives';
import * as personalAccessToken from './custom/PersonalAccessToken';
import * as plan from './custom/Plan';
import * as praise from './custom/Praise';
import * as praiseComment from './custom/PraiseComment';
import * as praiseExport from './custom/PraiseExport';
import * as praiseWallConfig from './custom/PraiseWallConfig';
import * as praiseWallToken from './custom/PraiseWallToken';
import * as reaction from './custom/Reaction';
import * as releaseNotes from './custom/ReleaseNotes';
import * as replacements from './custom/Replacements';
import * as requestFeedbackSummary from './custom/RequestFeedbackSummary';
import * as requestFeedbackSummaryDetails from './custom/RequestFeedbackSummaryDetails';
import * as retentionAnalyticsSettings from './custom/RetentionAnalyticsSettings';
import * as review from './custom/Review';
import * as reviewAssessments from './custom/ReviewAssessments';
import * as reviewCycles from './custom/ReviewCycles';
import * as reviewCyclesV2 from './custom/ReviewCyclesV2';
import * as reviewees from './custom/Reviewees';
import * as reviewGraph from './custom/ReviewGraph';
import * as reviewMini from './custom/ReviewMini';
import * as reviewQuestionnaire from './custom/ReviewQuestionnaire';
import * as reviewsForReviewee from './custom/ReviewsForReviewee';
import * as reviewsLegacy from './custom/ReviewsLegacy';
import * as roles from './custom/Roles';
import * as sampleContent from './custom/SampleContent';
import * as slackConfiguration from './custom/SlackConfiguration';
import * as sparks from './custom/Sparks';
import * as supportRequests from './custom/SupportRequests';
import * as surveyDates from './custom/SurveyDates';
import * as surveyResponses from './custom/SurveyResponses';
import * as surveys from './custom/Surveys';
import * as surveyTemplates from './custom/SurveyTemplates';
import * as unifiedFeedback from './custom/UnifiedFeedback';
import * as unifiedFeedbackDetails from './custom/UnifiedFeedbackDetails';
import * as unifiedFeedbackDetailsYouProvided from './custom/UnifiedFeedbackDetailsYouProvided';
import * as unifiedFeedbackYouProvided from './custom/UnifiedFeedbackYouProvided';
import * as upcomingAdminDates from './custom/UpcomingAdminDates';
import * as usageSourceStats from './custom/UsageSourceStats';
import * as userEngagement from './custom/UserEngagement';
import * as userImport from './custom/UserImport';
import * as userImportConfiguration from './custom/UserImportConfiguration';
import * as userMerge from './custom/UserMerge';
import * as userMergeStats from './custom/UserMergeStats';
import * as userObserver from './custom/UserObserver';
import * as userRelationship from './custom/UserRelationship';
import * as users from './custom/Users';
import * as userSettings from './custom/UserSettings';
import * as usersHR from './custom/UsersHR';
import * as usersList from './custom/UsersList';
import * as usersMedium from './custom/UsersMedium';
import * as yourReviews from './custom/YourReviews';
import * as yourSurveys from './custom/YourSurveys';
const config = {
    calendar: {
        api: calendar,
        ttl: 60 * 10,
    },
    actionItem: {
        api: actionItem,
        ttl: 60 * 5,
    },
    admin: {
        api: admin,
        ttl: 300,
    },
    aiSummaryAssistant: {
        api: aiSummaryAssistant,
        ttl: 300,
    },
    anytimeFeedbackDefault: {
        api: anytimeFeedbackDefault,
    },
    anytimeFeedbackRequest: {
        api: anytimeFeedbackRequest,
        ttl: 300,
        invalidates: [
            'requestFeedbackSummary',
            'unifiedFeedback',
            'unifiedFeedbackYouProvided',
            'unifiedFeedbackDetails',
            'unifiedFeedbackDetailsYouProvided',
            'anytimeFeedbackStats',
        ],
    },
    anytimeFeedbackResponse: {
        api: anytimeFeedbackResponse,
        ttl: 300,
        invalidates: [
            'actionItem',
            'requestFeedbackSummary',
            'unifiedFeedbackDetails',
            'unifiedFeedbackDetailsYouProvided',
            'anytimeFeedbackStats',
        ],
    },
    anytimeFeedbackExport: {
        api: anytimeFeedbackExport,
        ttl: 300,
    },
    anytimeFeedbackStats: {
        api: anytimeFeedbackStats,
        ttl: 300,
    },
    automations: {
        api: automations,
    },
    automationsOptions: {
        api: automationsOptions,
    },
    automationsSettings: {
        api: automationsSettings,
    },
    autocompletion: {
        api: autocompletion,
        ttl: 300,
    },
    cycleFeedbackResponse: {
        api: cycleFeedbackResponse,
        ttl: 300,
        invalidates: ['actionItem', 'unifiedFeedbackDetails', 'unifiedFeedbackDetailsYouProvided'],
    },
    calendarIntegration: {
        api: calendarIntegration,
    },
    currentUser: {
        api: currentUser,
        ttl: 86400,
    },
    customer: {
        api: customer,
        ttl: 300,
        invalidates: ['customerPaymentDoc'],
    },
    customerCO: {
        api: customerCO,
        ttl: 300,
        invalidates: ['customerPaymentDoc'],
    },
    planForCustomer: {
        api: plan.Customer,
        ttl: 300,
    },
    planForCompanyObserver: {
        api: plan.CompanyObserver,
        ttl: 300,
    },
    customerPaymentDoc: {
        api: customerPaymentDoc,
        ttl: 300,
    },
    customerHealth: {
        api: customerHealth,
        ttl: 300,
    },
    upcomingAdminDates: {
        ttl: 60,
        api: upcomingAdminDates,
    },
    company: {
        ttl: 60,
        api: company,
        invalidates: ['customerCO'],
    },
    companyList: {
        ttl: 300,
        api: companyList,
        viewOf: 'company',
    },
    companyDateTimeSettings: {
        ttl: 300,
        api: companyDateTimeSettings,
    },
    contentItemPreview: {
        ttl: 60,
        api: contentItemPreview,
    },
    downloads: {
        ttl: 300,
        api: downloads,
        invalidates: ['downloads'],
        invalidatesOn: ['DELETE'],
    },
    emailConfiguration: {
        api: emailConfiguration,
    },
    emailReportSubscriptions: {
        api: emailReportSubscriptions,
    },
    emailTemplatePreview: {
        ttl: 500,
        api: emailTemplatePreview,
    },
    emailTemplate: {
        ttl: 900,
        api: emailTemplate,
    },
    emailTemplateMini: {
        ttl: 900,
        api: emailTemplateMini,
        viewOf: 'emailTemplate',
    },
    emailTemplateDtoPreview: {
        api: emailTemplateDtoPreview,
    },
    emailTemplateModificationInfo: {
        ttl: 500,
        api: emailTemplateModificationInfo,
    },
    emailVerification: {
        api: emailVerification,
        ttl: 60,
    },
    experiments: {
        api: experiments,
        invalidates: ['experimentsMini', 'companyExperimentsMini'],
        invalidatesOn: ['CREATE', 'COMMAND'],
    },
    experimentsMini: {
        api: experimentsMini,
        viewOf: 'experiments',
    },
    companyExperimentsMini: {
        api: companyExperimentsMini,
    },
    exportJob: {
        ttl: 0,
        api: exportJob,
        invalidates: ['downloads'],
        invalidatesOn: ['NO_OPERATION'],
    },
    inAppFeedback: {
        api: inAppFeedback,
    },
    helpfulResources: {
        ttl: 10000,
        api: helpfulResources,
    },
    meetingCalendarIntegration: {
        api: meetingCalendarIntegration,
    },
    meetingCalendarSettings: {
        api: meetingCalendarSettings,
        invalidates: ['actionItem'],
        invalidatesOn: ['NO_OPERATION'],
    },
    meetings: {
        api: meetings,
        invalidates: ['meetingsRelevant', 'actionItem', 'meetingSpaces', 'meetingSpacesMini'],
        invalidatesOn: ['DELETE', 'CREATE', 'UPDATE'],
    },
    meetingTemplates: {
        api: meetingTemplates,
    },
    meetingsRelevant: {
        ttl: 300,
        api: meetingsRelevant,
    },
    meetingInsights: {
        ttl: 300,
        api: meetingInsights,
    },
    meetingMetadata: {
        api: meetingMetadata,
    },
    meetingDashboard: {
        ttl: 0,
        api: meetingDashboard,
    },
    meetingSpaces: {
        ttl: 300,
        api: meetingSpaces,
    },
    meetingSpacesMini: {
        ttl: 300,
        api: meetingSpacesMini,
        viewOf: 'meetingSpaces',
    },
    meetingSpacesCount: {
        ttl: 300,
        api: meetingSpacesCount,
    },
    messagePeerFeedbackExport: {
        ttl: 0,
        api: messagePeerFeedbackExport,
    },
    messageSettings: {
        ttl: 10000,
        api: messageSettings,
    },
    messageStatistics: {
        ttl: 10000,
        api: messageStatistics,
    },
    messagesRelevant: {
        ttl: 300,
        api: messagesRelevant,
        // viewOf: '_messages',
    },
    meetingActions: {
        api: meetingActions,
    },
    objectives: {
        api: objectives,
    },
    objectiveClippy: {
        api: objectiveClippy,
        ttl: 600,
    },
    objectivePreview: {
        api: objectivePreview,
    },
    objectiveCycle: {
        api: objectiveCycle,
    },
    objectiveComments: {
        api: objectiveComments,
        invalidates: ['objectiveCommentCount', 'activityStream', 'actionItem'],
    },
    objectiveCommentCount: {
        api: objectiveCommentCount,
    },
    objectiveCategories: {
        api: objectiveCategories,
    },
    objectiveRatings: {
        api: objectiveRatings,
    },
    praise: {
        ttl: 300,
        api: praise,
        invalidates: ['activityStream'],
    },
    praiseComment: {
        ttl: 300,
        api: praiseComment,
        invalidates: ['activityStream'],
    },
    praiseWallToken: {
        ttl: 10000,
        api: praiseWallToken,
    },
    praiseWallConfig: {
        ttl: 60 * 60 * 20,
        api: praiseWallConfig,
    },
    praiseExport: {
        ttl: 10000,
        api: praiseExport,
    },
    reviews: {
        ttl: 300,
        api: review,
        invalidates: ['reviewGraph', 'actionItem', 'reviewsForReviewee', 'reviewAssessments', 'reviewsLegacy'],
    },
    reviewAssessments: {
        ttl: 300,
        api: reviewAssessments,
        invalidates: ['reviews', 'yourReviews', 'actionItem'],
    },
    reviewMini: {
        ttl: 1000,
        api: reviewMini,
    },
    reviewGraph: {
        ttl: 1000,
        api: reviewGraph,
    },
    reviewsForReviewee: {
        ttl: 300,
        api: reviewsForReviewee,
    },
    importReviews: {
        api: importReviews,
    },
    reviewsLegacy: {
        api: reviewsLegacy,
        invalidates: ['reviews'],
    },
    reviewCycles: {
        ttl: 300,
        api: reviewCycles,
    },
    reviewCyclesV2: {
        ttl: 300,
        api: reviewCyclesV2,
    },
    requestFeedbackSummary: {
        api: requestFeedbackSummary,
    },
    requestFeedbackSummaryDetails: {
        api: requestFeedbackSummaryDetails,
    },
    slackConfiguration: {
        api: slackConfiguration,
    },
    sparks: {
        ttl: 1000,
        api: sparks,
    },
    feedbackCycles: {
        api: feedbackCycles,
    },
    feedbackParticipants: {
        api: feedbackParticipants,
        invalidates: [
            'actionItem',
            'unifiedFeedback',
            'unifiedFeedbackYouProvided',
            'unifiedFeedbackDetails',
            'unifiedFeedbackDetailsYouProvided',
        ],
        invalidatesOn: ['NO_OPERATION'],
    },
    feedbackRequestManagement: {
        api: feedbackRequestManagement,
        invalidates: [
            'actionItem',
            'unifiedFeedback',
            'unifiedFeedbackYouProvided',
            'unifiedFeedbackDetails',
            'unifiedFeedbackDetailsYouProvided',
        ],
        invalidatesOn: ['NO_OPERATION'],
    },
    feedbackTemplateAnytime: {
        invalidates: ['feedbackTemplateOrder', 'automations'],
        invalidatesOn: ['CREATE'],
        ttl: 1000,
        api: feedbackTemplateAnytime,
    },
    feedbackTemplateCycle: {
        invalidates: ['feedbackTemplateOrder'],
        invalidatesOn: ['CREATE'],
        ttl: 1000,
        api: feedbackTemplateCycle,
    },
    feedbackParticipantMini: {
        api: feedbackParticipantMini,
        ttl: 1000,
    },
    feedbackTemplateOrder: {
        api: feedbackTemplateOrder,
        ttl: 1000,
    },
    googleAppsLoginConfig: {
        api: googleAppsLoginConfig,
    },
    reaction: {
        api: reaction,
    },
    bambooImport: {
        api: userImport.createApi('BAMBOO'),
    },
    bambooImportConfiguration: {
        api: userImportConfiguration.createApi('BAMBOO'),
    },
    csvImport: {
        api: userImport.createApi('CSV'),
    },
    csvImportConfiguration: {
        api: userImportConfiguration.createApi('CSV'),
    },
    namelyImport: {
        api: userImport.createApi('NAMELY'),
    },
    namelyImportConfiguration: {
        api: userImportConfiguration.createApi('NAMELY'),
    },
    personioImport: {
        api: userImport.createApi('PERSONIO'),
    },
    personioImportConfiguration: {
        api: userImportConfiguration.createApi('PERSONIO'),
    },
    saplingImport: {
        api: userImport.createApi('SAPLING'),
    },
    saplingImportConfiguration: {
        api: userImportConfiguration.createApi('SAPLING'),
    },
    googleWorkspaceImport: {
        api: userImport.createApi('GOOGLE_APPS'),
    },
    googleWorkspaceImportConfiguration: {
        api: userImportConfiguration.createApi('GOOGLE_APPS'),
    },
    notificationsCount: {
        ttl: 30,
        api: notificationsCount,
    },
    notifications: {
        ttl: 300,
        api: notifications,
        invalidates: ['notificationsCount'],
        invalidatesOn: ['UPDATE'],
    },
    activityStream: {
        ttl: 300,
        api: activityStream,
    },
    usersMedium: {
        ttl: 10000,
        viewOf: 'users',
        api: usersMedium,
        invalidates: ['users'],
    },
    users: {
        ttl: 10000,
        api: users,
        invalidates: ['activityStream', 'autocompletion'],
    },
    usersList: {
        ttl: 10000,
        viewOf: 'users',
        api: usersList,
    },
    userSettings: {
        ttl: 10000,
        api: userSettings,
        invalidates: ['currentUser'],
    },
    usersHR: {
        ttl: 10000,
        api: usersHR,
    },
    userMerge: {
        api: userMerge,
    },
    userRelationship: {
        ttl: 10000,
        api: userRelationship,
    },
    roles: {
        ttl: 10000,
        api: roles,
    },
    userMergeStats: {
        ttl: 10000,
        api: userMergeStats,
    },
    yourReviews: {
        ttl: 30,
        api: yourReviews,
    },
    surveys: {
        api: surveys,
        invalidates: ['surveyResponses', 'surveyDates', 'yourSurveys', 'actionItem'],
    },
    surveyTemplates: {
        ttl: 10000,
        api: surveyTemplates,
    },
    surveyResponses: {
        ttl: 10000,
        api: surveyResponses,
    },
    surveyDates: {
        ttl: 10000,
        api: surveyDates,
    },
    yourSurveys: {
        api: yourSurveys,
        invalidates: ['surveys', 'surveyResponses'],
    },
    backups: {
        api: backups,
    },
    badges: {
        ttl: 10000,
        api: badges,
    },
    usageSourceStats: {
        ttl: 300,
        api: usageSourceStats,
    },
    supportRequests: {
        api: supportRequests,
    },
    userEngagement: {
        ttl: 1200,
        api: userEngagement,
    },
    replacements: {
        ttl: 600,
        api: replacements,
    },
    auditLogCategories: {
        ttl: 10000,
        api: auditLogCategories,
    },
    auditLogTypes: {
        ttl: 10000,
        api: auditLogTypes,
    },
    auditLog: {
        ttl: 1000,
        api: auditLog,
    },
    auditLogMedium: {
        ttl: 1000,
        api: auditLogMedium,
        // not a view of auditLog at the moment, because cursor handling
        // not fully implemented in ladda yet
    },
    mailEvents: {
        api: mailEvents,
    },
    bouncedEmail: {
        api: bouncedEmail,
    },
    bouncedEmailAggregate: {
        ttl: 0,
        api: bouncedEmailAggregate,
        // Invalidate all places where we show warnings/errors about bounces, so they can disappear
        invalidates: ['users', 'admin'],
        invalidatesOn: ['DELETE'],
    },
    deletedUser: {
        api: deletedUser,
    },
    featureFlag: {
        api: featureFlag,
        ttl: 10000,
    },
    featureSetting: {
        api: featureSetting,
        ttl: 10000,
    },
    featureSelectionSettings: {
        api: featureSelectionSettings,
        ttl: 10000,
    },
    reviewees: {
        api: reviewees,
        ttl: 300,
    },
    unifiedFeedback: {
        api: unifiedFeedback,
        viewOf: 'unifiedFeedbackDetails',
        ttl: 300,
    },
    unifiedFeedbackYouProvided: {
        api: unifiedFeedbackYouProvided,
        ttl: 300,
    },
    unifiedFeedbackDetails: {
        api: unifiedFeedbackDetails,
        ttl: 300,
    },
    unifiedFeedbackDetailsYouProvided: {
        api: unifiedFeedbackDetailsYouProvided,
        ttl: 300,
    },
    userObserver: {
        api: userObserver,
    },
    companyAnalytics: {
        api: companyAnalytics,
    },
    retentionAnalyticsSettings: {
        api: retentionAnalyticsSettings,
    },
    companyAnalyticsLeaveRisk: {
        api: companyAnalyticsLeaveRisk,
    },
    companyAnalyticsCycleData: {
        api: companyAnalyticsCycleData,
    },
    sampleContent: {
        api: sampleContent,
    },
    personalAccessToken: {
        api: personalAccessToken,
        ttl: 100,
    },
    cycleAdmins: {
        api: cycleAdmins,
    },
    releaseNotes: {
        api: releaseNotes,
    },
    reviewQuestionnaire: {
        api: reviewQuestionnaire,
    },
    // Dummy apis for cache control
    __objectives: {
        invalidates: ['activityStream', 'actionItem', 'objectiveClippy'],
        invalidatesOn: ['CREATE', 'DELETE', 'UPDATE'],
        api: dummyApi,
    },
    __messages: {
        invalidates: ['activityStream', 'messagesRelevant'],
        invalidatesOn: ['CREATE', 'DELETE', 'UPDATE'],
        api: dummyApi,
    },
    _user: {
        invalidates: ['activityStream'],
        invalidatesOn: ['CREATE', 'DELETE', 'UPDATE'],
        api: dummyApi,
    },
    _reviewOperations: {
        invalidates: ['activityStream'],
        invalidatesOn: ['CREATE', 'DELETE', 'UPDATE'],
        api: dummyApi,
    },
    _reviewCycleOperations: {
        invalidates: ['reviewAssessments', 'reviews'],
        invalidatesOn: ['CREATE', 'DELETE', 'UPDATE'],
        api: dummyApi,
    },
};
const plugins = __DEV__ && !__TEST__ ? [logger(), observable()] : [observable()];
const api = build(config, plugins);
const dummyApis = toPairs(api);
const configureDummy = oneApi => mapValues(oneApi, x => partial(x, { id: 'fakeId' }));
const dummyApisConfigured = map(dummyApis, ([name, x]) => startsWith(name, '_') ? [name.substring(1), configureDummy(x)] : [name, x]);
export default fromPairs(dummyApisConfigured);
