import { get, startsWith } from 'lodash/fp';
import { trackError } from '../../../../app-config/errorTracking';
import { Plan } from '../../../../types/CompanyPaymentDto';
import moment from 'moment';
async function hashIdForSprig(id) {
    // We hash IDs for security (not for privacy as the IDs are already psuedo-anonymized,) so that if
    // we have an IDOR vulnerability, an attacker can't use the IDs from Sprig to exploit it.
    // Sprig also suggest this in their documentation: https://docs.google.com/document/d/1qMO3DaXklt9csAPfWNolBpPecAiQ55350ke2_nwFSAs/edit
    const buffer = new TextEncoder().encode(id);
    const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}
function isInTrial(currentUser) {
    if (currentUser.company.payment.trialUntil) {
        return moment().isBefore(currentUser.company.payment.trialUntil);
    }
    return false;
}
function shouldSyncUser(currentUser) {
    const userCount = currentUser.company.payment.userCount;
    const inTargetGroup = (userCount >= 50 && userCount <= 500) || currentUser.isAdmin || currentUser.isHR;
    return (get('allowSprig', currentUser) &&
        !currentUser.company.companySettings.optOutExternalServicesDataSync &&
        inTargetGroup);
}
function getEnvironmentId() {
    const SPRIG_DEV_ENVIRONMENT_ID = 'iFTe0-LovacZ';
    const SPRIG_PROD_ENVIRONMENT_ID = 'tHQuPvpmEUhK';
    const currentUser = window._currentUser;
    if (!currentUser) {
        return undefined;
    }
    if (!shouldSyncUser(currentUser)) {
        return undefined;
    }
    const isInProduction = startsWith('prod', get('company.server', currentUser));
    return isInProduction ? SPRIG_PROD_ENVIRONMENT_ID : SPRIG_DEV_ENVIRONMENT_ID;
}
let sprigSdk;
async function getSprig() {
    const environmentId = getEnvironmentId();
    if (environmentId === undefined) {
        return undefined;
    }
    if (!sprigSdk) {
        const Sprig = await import(/* webpackChunkName: 'SprigSDK' */ '@sprig-technologies/sprig-browser');
        sprigSdk = Sprig.sprig.configure({ environmentId });
    }
    return sprigSdk;
}
export async function initSprig() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    try {
        const currentUser = window._currentUser;
        if (!currentUser) {
            return;
        }
        if (__DEV__) {
            // eslint-disable-next-line no-console
            console.log('[Sprig] would have initialized if in prod');
            return;
        }
        const sprig = await getSprig();
        if (!sprig) {
            return;
        }
        if (!currentUser.isImpersonated) {
            const userId = await hashIdForSprig(currentUser.id);
            const companyId = await hashIdForSprig(currentUser.company.id);
            try {
                await sprig.setUserId(userId);
                await sprig.setAttributes({
                    'Is HR?': currentUser.isHR,
                    'Is HR Assistant?': currentUser.isAssistant,
                    'Is Tech Admin?': currentUser.isAdmin,
                    'Is Manager?': !!((_a = currentUser.reports) === null || _a === void 0 ? void 0 : _a.length),
                    'Total Logins by User': currentUser.loginCounter,
                    // Company information
                    'Company ID': companyId,
                    'Licenses Paid': (_c = (_b = currentUser.company) === null || _b === void 0 ? void 0 : _b.payment) === null || _c === void 0 ? void 0 : _c.licensesPaid,
                    'User Count': (_d = currentUser.company) === null || _d === void 0 ? void 0 : _d.userCount,
                    'Pricing Plan': Plan[(_f = (_e = currentUser.company) === null || _e === void 0 ? void 0 : _e.payment) === null || _f === void 0 ? void 0 : _f.plan],
                    'Is Company 50 - 500 Users?': (((_g = currentUser.company) === null || _g === void 0 ? void 0 : _g.userCount) >= 50 && ((_h = currentUser.company) === null || _h === void 0 ? void 0 : _h.userCount) <= 500) || false,
                    'Is Paying?': ((_j = currentUser.company) === null || _j === void 0 ? void 0 : _j.payment.paidTo) !== null &&
                        moment().isBefore((_k = currentUser.company) === null || _k === void 0 ? void 0 : _k.payment.paidTo),
                    'Given Sample Content?': (_l = currentUser.company) === null || _l === void 0 ? void 0 : _l.sampleContentOnCreation,
                    'In Trial': isInTrial(currentUser),
                });
            }
            catch (e) {
                trackError(e);
                sprigSdk = undefined;
            }
        }
    }
    catch (e) {
        trackError(e);
        sprigSdk = undefined;
    }
}
// Events are used to trigger "Studies" etc. in Sprig.
// New events need to be confirmed in Sprig before use, see: https://docs.sprig.com/docs/events#reactive
export async function trackSprigEvent(eventName, properties = {}) {
    try {
        const currentUser = window._currentUser;
        if (!currentUser) {
            return;
        }
        if (__DEV__) {
            // eslint-disable-next-line no-console
            console.log('[Sprig] track event', eventName, properties);
            return;
        }
        const sprig = await getSprig();
        if (!sprig) {
            return;
        }
        const userId = await hashIdForSprig(currentUser.id);
        try {
            await sprig.identifyAndTrack({
                eventName,
                userId,
                properties,
            });
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.log('Error tracking Sprig event:', e);
        }
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error tracking Sprig event:', e);
    }
}
