import { map, fromPairs, compose } from 'lodash/fp';
const fnNames = ['getItem', 'setItem', 'removeItem'];
export const sessionStorage = getSafeStorage(window.sessionStorage);
export const localStorage = getSafeStorage(window.localStorage);
function getSafeStorage(storage) {
    return compose(fromPairs, map(fnName => [fnName, wrapTry(storage, fnName)]))(fnNames);
}
function wrapTry(storage, fnName) {
    return (...args) => {
        try {
            return storage[fnName].call(storage, ...args);
        }
        catch (error) {
            if (process.env.NODE_ENV !== 'production') {
                console.error(error); // eslint-disable-line no-console
            }
            return null;
        }
    };
}
